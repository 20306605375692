<template>
  <div>
    <EmployeeSearchDialog
      ref="employeeSearch"
      :lists="form.employees"
      value="NIP"
      @handle-pick="pickItem"
    />
    <GroupNIPSearchDialog
      ref="groupNIPSearch"
      :lists="[]"
      value="groupid"
      @handle-pick="pickGroupItem"
    />
    <v-dialog v-model="visible" persistent max-width="800">
      <v-card class="pa-5 rounded-lg" id="card-custom">
        <v-btn @click="visible = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          Keterangan Izin
        </v-card-text>
        <v-divider class="mb-4" />
        <v-form
          id="form"
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="removeNote()"
        >
          <v-row>
            <template v-if="single">
              <v-col cols="3" class="py-1">
                <v-subheader class="caption headline-color font-weight-regular"
                  >Nama/NIP</v-subheader
                >
              </v-col>
              <v-col cols="9" class="py-1">
                <v-text-field
                  v-if="form.employees[0]"
                  :value="
                    `${form.employees[0].name} - ${form.employees[0].NIP}`
                  "
                  outlined
                  dense
                  disabled
                ></v-text-field>
              </v-col>
            </template>
            <v-col cols="12" class="py-1" v-else>
              <v-subheader class="caption headline-color font-weight-regular"
                >List Pegawai</v-subheader
              >
              <v-data-table
                id="table-custom-pegawai"
                class="mt-3"
                :headers="employeeHeaders"
                :items="form.employees"
              >
                <template v-slot:[`item`]="{ item, index }">
                  <tr>
                    <td class="pt-2">
                      {{ item.NIP }}
                    </td>
                    <td class="pt-2">
                      {{ item.name }}
                    </td>
                    <td>
                      <v-btn
                        small
                        color="#FBB005"
                        class="ml-2 white--text"
                        @click="form.employees.splice(index, 1)"
                        >Hapus</v-btn
                      >
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  text
                  class="text-capitalize caption headline-color"
                  color="primary"
                  @click="$refs.employeeSearch.change(true)"
                  >Tambah<v-icon class="ml-2" color="primary"
                    >add_circle</v-icon
                  ></v-btn
                >
                <v-btn
                  text
                  class="text-capitalize caption headline-color"
                  color="primary"
                  @click="$refs.groupNIPSearch.change(true)"
                  >Tambah Group NIP<v-icon class="ml-2" color="primary"
                    >add_circle</v-icon
                  ></v-btn
                >
              </v-card-actions>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Tanggal Mulai</v-subheader
              >
            </v-col>
            <v-col cols="3" class="py-1">
              <v-text-field
                v-model="form.date_start"
                type="date"
                outlined
                dense
                :rules="[v => !!v || 'Tanggal mulai harus diisi']"
              ></v-text-field>
            </v-col>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Tanggal Akhir</v-subheader
              >
            </v-col>
            <v-col cols="3" class="py-1">
              <v-text-field
                v-model="form.date_end"
                type="date"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Alasan</v-subheader
              >
            </v-col>
            <v-col cols="9" class="py-1">
              <v-textarea
                placeholder="Tulis Alasan"
                v-model="form.remark"
                outlined
                dense
                :rules="[v => !!v || 'Alasan harus diisi']"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-1 px-7">
              <p class="caption text-subtitle mb-0">
                Isi dengan tanggal yang sama atau kosongkan kolom tanggal kedua
                jika hanya izin 1 hari
              </p>
            </v-col>
          </v-row>
          <v-card-actions class="mt-3">
            <v-spacer />
            <slot name="action">
              <v-btn
                small
                outlined
                color="#717171"
                class="px-12"
                @click="visible = false"
                >Batal</v-btn
              >
              <v-btn
                small
                color="#FBB005"
                class="px-12 subtitle-2 text-capitalize"
                :loading="formLoading"
                type="submit"
                :disabled="!valid"
                form="form"
                >Hapus</v-btn
              >
            </slot>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import AttendanceService from "@/services/resources/attendance.service";

const EmployeeSearchDialog = () => import("@/components/Dialog/EmployeeSearch");
const GroupNIPSearchDialog = () => import("@/components/Dialog/GroupNIPSearch");

export default {
  components: {
    EmployeeSearchDialog,
    GroupNIPSearchDialog
  },
  props: {
    single: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      visible: false,
      valid: false,
      formLoading: false,
      form: {
        date_start: null,
        date_end: null,
        remark: null,
        employees: []
      },
      employeeHeaders: [
        {
          text: "NIP",
          value: "NIP",
          sortable: false,
          align: "center"
        },
        {
          text: "Nama",
          value: "name",
          sortable: false,
          align: "center"
        },
        {
          text: "Aksi",
          value: "actions",
          sortable: false,
          align: "center"
        }
      ]
    };
  },
  watch: {
    visible(val) {
      if (!val) {
        this.form.date_start = null;
        this.form.date_end = null;
        this.form.remark = null;
        this.form.employees = [];
        if (this.$refs.fileDraggable) {
          this.$refs.fileDraggable.closeAlert();
          this.$refs.fileDraggable.removeFile();
        }
        this.$refs.form.reset();
      }
    }
  },
  methods: {
    createBulk() {
      this.visible = true;
    },
    createSingle(item) {
      this.form.employees = item.employees;
      this.visible = true;
    },
    pickItem(item) {
      if (item) {
        let index = this.form.employees.findIndex(d => d.NIP == item.NIP);
        if (index < 0) {
          this.form.employees.push({
            NIP: item.NIP,
            name: item.nama
          });
        }
      }
    },
    pickGroupItem(item) {
      if (item) {
        item.pegawaiList.map(pegawai => {
          this.pickItem(pegawai);
        });
      }
    },
    removeNote() {
      if (this.$refs.form.validate()) {
        this.$confirm({
          title: "Konfirmasi",
          message: `Apakah anda yakin akan menghapus catatan ini?`,
          button: {
            no: "Batal",
            yes: "Ya"
          },
          callback: confirm => {
            if (confirm) {
              let formData = new FormData();
              this.form.employees.forEach(employee => {
                formData.append("NIP[]", employee.NIP);
              });
              formData.append("date_start", this.form.date_start);
              formData.append(
                "date_end",
                this.form.date_end || this.form.date_start
              );
              formData.append("remark", this.form.remark);
              this.deleteNote(formData);
            }
          }
        });
      }
    },
    // Service
    async deleteNote(payload) {
      try {
        this.formLoading = true;
        await AttendanceService.deleteNote(payload)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.visible = false;
              this.$emit("on-refresh");
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>
